import { useEffect, useState } from "react";
import { useAppState, Steps } from "../../state";
import { styled } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import useHeight from "../../hooks/useHeight/useHeight";
import IntroContainer from "../IntroContainer/IntroContainer";
import HelpDeskView from "./HelpDeskView";
import FindItemView from "./FindItemView";
import PickOrderView from "./PickOrderView";
import { ClientFetch, ClientCoverImage } from "../../data/ClientData";

const Container = styled("div")({
  display: "grid",
  gridTemplateRows: "1fr auto"
});

export default function LandingClient() {
  const {
    isFetching,
    currentStep,
    setCurrentStep,
    clientID,
    setClientID,
    locationID,
    setLocationID,
    setDcxClientData,
    logoURL,
    setLogoURL,
    dcxClientName,
    setDcxClientName,
    serviceURL,
    setServiceURL,
    coverURL,
    setCoverURL
  } = useAppState();

  const [clientExist, setClientExist] = useState(false);
  const [selectedOption] = useState("");
  const height = useHeight();
  const url = new URL(window.location.href);
  const urlClientID = url.searchParams.get("ClientID") as string;
  const urlLocationID = url.searchParams.get("LocationID") as string;
  const clientCover = ClientCoverImage(urlClientID);
  const dcxClient = {
    clientID,
    locationID,
    dcxClientName,
    logoURL,
    coverURL,
    serviceURL
  };
  localStorage.setItem("DCXCLIENT", JSON.stringify(dcxClient));

  useEffect(() => {
    const controller = new AbortController();
    localStorage.removeItem("DCXCLIENTCustomer");
    localStorage.removeItem("DCXCLIENT");
    localStorage.removeItem("DCXCliSig");

    const fetchClient = async () => {
      try {
        const acceptedURL = window.location.search.toString();
        if (!urlClientID) {
          window.location.assign("/landing");
          return;
        }
        const clientData = await ClientFetch(urlClientID);
        setClientID(urlClientID);
        setClientExist(true);
        setDcxClientData(clientData);
        setServiceURL(acceptedURL);
        setLocationID(urlLocationID);
        setLogoURL(clientData.PhoneLogoURL);
        if (clientData.ClientName.includes("&amp;"))
          clientData.ClientName.replace("&amp;", "&");
        setDcxClientName(clientData.ClientName);
        setCoverURL(clientCover);
        setCurrentStep(Steps.landingAccountPage);
      } catch (error) {
        console.log(error);
        setCurrentStep(Steps.landingPage);
      }
    };
    fetchClient();

    return () => controller.abort();
  }, [
    clientID,
    urlLocationID,
    setCoverURL,
    setCurrentStep,
    isFetching,
    selectedOption,
    setClientID,
    setLocationID,
    urlClientID,
    setDcxClientName,
    setLogoURL,
    setServiceURL,
    dcxClientName,
    locationID,
    logoURL,
    serviceURL,
    clientCover,
    coverURL,
    setDcxClientData
  ]);

  return (
    <Container style={{ height }}>
      <IntroContainer logoURL={logoURL} clientName={dcxClientName}>
        {clientExist && currentStep === Steps.landingAccountPage && (
          <HelpDeskView />
        )}
        {clientExist && currentStep === Steps.findItemView && <FindItemView />}
        {clientExist && currentStep === Steps.pickOrderView && (
          <PickOrderView />
        )}

        {!clientExist && (
          <div className="centrado">
            <br />
            <br />
            Gathering client information
            <br />
            <br />
            <CircularProgress />
          </div>
        )}
      </IntroContainer>
    </Container>
  );
}
