import React, { createContext, useContext, useReducer, useState } from "react";
import { RecordingRules, RoomType } from "../types";
import { TwilioError } from "twilio-video";
import {
  settingsReducer,
  initialSettings,
  Settings,
  SettingsAction
} from "./settings/settingsReducer";
import useActiveSinkId from "./useActiveSinkId/useActiveSinkId";
import useFirebaseAuth from "./useFirebaseAuth/useFirebaseAuth";
import { useLocalStorageState } from "../hooks/useLocalStorageState/useLocalStorageState";
import usePasscodeAuth from "./usePasscodeAuth/usePasscodeAuth";
import { User } from "firebase/auth";

export const StateContext = createContext<StateContextType>(null!);

export enum Steps {
  landingAccountPage,
  findItemView,
  landingPage,
  roomNameStep,
  selectContactChannel,
  chatLobby,
  videoLobby,
  joinChatRoom,
  goToCallStep,
  pickOrderView
}

export interface StateContextType {
  clientID: string;
  setClientID: (clientID: string) => void;
  customerPhone: string;
  setCustomerPhone: (customerPhone: string) => void;
  customerDCXId: string;
  setCustomerDCXId: (customerDCXId: string) => void;
  userAppId: string;
  setUserAppId: (userAppId: string) => void;
  hRequestId: string;
  setHRequestId: (hRequestId: string) => void;
  commChannel: string;
  setCommChannel: (commChannel: string) => void;
  findQuery: string;
  setFindQuery(findQuery: string): void;
  serverURL: string;
  setServerURL(serverURL: string): void;
  serviceURL: string;
  setServiceURL(serviceURL: string): void;
  hBoxRequest: string;
  setHBoxRequest(hBoxRequest: string): void;
  suggestedDepartment: string;
  setSuggestedDepartment(suggestedDepartment: string): void;
  clientExist: boolean;
  setClientExist(clientExist: boolean): void;
  dcxClientData: any;
  setDcxClientData(dcxClientData: any): void;
  dcxClientName: string;
  setDcxClientName(dcxClientName: string): void;
  logoURL: string;
  setLogoURL(logoURL: string): void;
  coverURL: string;
  setCoverURL(coverURL: string): void;
  error: TwilioError | Error | null;
  setError(error: TwilioError | Error | null): void;
  getToken(
    name: string,
    room: string,
    passcode?: string
  ): Promise<{ room_type: RoomType; token: string }>;
  user?:
    | User
    | null
    | { displayName: undefined; photoURL: undefined; passcode?: string };
  signIn?(passcode?: string): Promise<void>;
  signOut?(): Promise<void>;
  isAuthReady?: boolean;
  isFetching: boolean;
  activeSinkId: string;
  locationID: string;
  setLocationID: (locationID: string) => void;
  visitorName: string;
  setVisitorName: (visitorName: string) => void;
  setActiveSinkId(sinkId: string): void;
  settings: Settings;
  dispatchSetting: React.Dispatch<SettingsAction>;
  roomType?: RoomType;
  currentStep: Steps;
  setCurrentStep: (setCurrentStep: Steps) => void;
  isAgent(): boolean;
  isVideoCall(): boolean;
  updateRecordingRules(
    room_sid: string,
    rules: RecordingRules
  ): Promise<object>;
  isGalleryViewActive: boolean;
  setIsGalleryViewActive: React.Dispatch<React.SetStateAction<boolean>>;
  maxGalleryViewParticipants: number;
  setMaxGalleryViewParticipants: React.Dispatch<React.SetStateAction<number>>;
  isKrispEnabled: boolean;
  setIsKrispEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  isKrispInstalled: boolean;
  setIsKrispInstalled: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function AppStateProvider(props: React.PropsWithChildren<{}>) {
  const [error, setError] = useState<TwilioError | null>(null);
  const [isGalleryViewActive, setIsGalleryViewActive] = useLocalStorageState(
    "gallery-view-active-key",
    true
  );
  const [
    maxGalleryViewParticipants,
    setMaxGalleryViewParticipants
  ] = useLocalStorageState("max-gallery-participants-key", 6);
  const [activeSinkId, setActiveSinkId] = useActiveSinkId();
  const [settings, dispatchSetting] = useReducer(
    settingsReducer,
    initialSettings
  );
  const [currentStep, setCurrentStep] = useState(0);
  const [roomType, setRoomType] = useState<RoomType>();
  const [isFetching, setIsFetching] = useState(false);
  const [clientExist, setClientExist] = useState(false);
  const [isKrispEnabled, setIsKrispEnabled] = useState(false);
  const [isKrispInstalled, setIsKrispInstalled] = useState(false);
  const [visitorName, setVisitorName] = useState("");
  const [clientID, setClientID] = useState("");
  const [locationID, setLocationID] = useState("");
  const [logoURL, setLogoURL] = useState("");
  const [coverURL, setCoverURL] = useState("");
  const [dcxClientName, setDcxClientName] = useState("");
  const [dcxClientData, setDcxClientData] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [customerDCXId, setCustomerDCXId] = useState("");
  const [suggestedDepartment, setSuggestedDepartment] = useState("");
  const [serviceURL, setServiceURL] = useState("");
  const [hBoxRequest, setHBoxRequest] = useState("");
  const [serverURL, setServerURL] = useState("");
  const [findQuery, setFindQuery] = useState("");
  const [userAppId, setUserAppId] = useState("");
  const [commChannel, setCommChannel] = useState("");
  const [hRequestId, setHRequestId] = useState("");

  let contextValue = {
    clientExist,
    setClientExist,
    customerPhone,
    setCustomerPhone,
    customerDCXId,
    setCustomerDCXId,
    serviceURL,
    setServiceURL,
    suggestedDepartment,
    setSuggestedDepartment,
    hBoxRequest,
    setHBoxRequest,
    serverURL,
    setServerURL,
    coverURL,
    setCoverURL,
    userAppId,
    setUserAppId,
    commChannel,
    setCommChannel,
    hRequestId,
    setHRequestId,
    findQuery,
    setFindQuery,
    dcxClientData,
    setDcxClientData,
    dcxClientName,
    setDcxClientName,
    logoURL,
    setLogoURL,
    clientID,
    setClientID,
    locationID,
    setLocationID,
    error,
    currentStep,
    setCurrentStep,
    visitorName,
    setVisitorName,
    setError,
    isFetching,
    activeSinkId,
    setActiveSinkId,
    settings,
    dispatchSetting,
    roomType,
    isGalleryViewActive,
    setIsGalleryViewActive,
    maxGalleryViewParticipants,
    setMaxGalleryViewParticipants,
    isKrispEnabled,
    setIsKrispEnabled,
    isKrispInstalled,
    setIsKrispInstalled
  } as StateContextType;

  if (process.env.REACT_APP_SET_AUTH === "firebase") {
    contextValue = {
      ...contextValue,
      ...useFirebaseAuth() // eslint-disable-line react-hooks/rules-of-hooks
    };
  } else if (process.env.REACT_APP_SET_AUTH === "passcode") {
    contextValue = {
      ...contextValue,
      ...usePasscodeAuth() // eslint-disable-line react-hooks/rules-of-hooks
    };
  } else {
    contextValue = {
      ...contextValue,
      getToken: async (user_identity, room_name) => {
        const endpoint = process.env.REACT_APP_TOKEN_ENDPOINT || "/token";

        return fetch(endpoint, {
          method: "POST",
          headers: {
            "content-type": "application/json"
          },
          body: JSON.stringify({
            user_identity,
            room_name,
            create_conversation:
              process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== "true"
          })
        }).then(res => res.json());
      },
      updateRecordingRules: async (room_sid, rules) => {
        const endpoint =
          process.env.REACT_APP_TOKEN_ENDPOINT || "/recordingrules";

        return fetch(endpoint, {
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ room_sid, rules }),
          method: "POST"
        })
          .then(async res => {
            const jsonResponse = await res.json();

            if (!res.ok) {
              const recordingError = new Error(
                jsonResponse.error?.message ||
                  "There was an error updating recording rules"
              );
              recordingError.code = jsonResponse.error?.code;
              return Promise.reject(recordingError);
            }

            return jsonResponse;
          })
          .catch(err => setError(err));
      }
    };
  }

  const getToken: StateContextType["getToken"] = (name, room) => {
    setIsFetching(true);
    return contextValue
      .getToken(name, room)
      .then(res => {
        setRoomType(res.room_type);
        console.log(res);
        setIsFetching(false);
        return res;
      })
      .catch(err => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  const updateRecordingRules: StateContextType["updateRecordingRules"] = (
    room_sid,
    rules
  ) => {
    setIsFetching(true);
    return contextValue
      .updateRecordingRules(room_sid, rules)
      .then(res => {
        setIsFetching(false);
        return res;
      })
      .catch(err => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  const isAgent: StateContextType["isAgent"] = () => {
    const url = new URL(window.location.href);
    return url.searchParams.has("agent") ? true : false;
  };

  const isVideoCall: StateContextType["isVideoCall"] = () => {
    const url = new URL(window.location.href);
    return url.searchParams.has("video") ? true : false;
  };

  return (
    <StateContext.Provider
      value={{
        ...contextValue,
        getToken,
        updateRecordingRules,
        isAgent,
        isVideoCall,
        currentStep,
        setCurrentStep
      }}
    >
      {props.children}
    </StateContext.Provider>
  );
}

export function useAppState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error("useAppState must be used within the AppStateProvider");
  }
  return context;
}
