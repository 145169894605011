import { useState, useEffect, FormEvent } from "react";
import { useParams } from "react-router-dom";
import { useAppState, Steps } from "../../state";
import {
  ClientCoverImage,
  RecordDCXCustomer,
  aiDeptChoose,
  createDBUser,
  getDBUser,
  saveDBHRequest,
  updateDBUser,
  getDBHRequest
} from "../../data/ClientData";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";
import MediaErrorSnackbar from "./MediaErrorSnackbar/MediaErrorSnackbar";
import LandingScreen from "../Screens/LandingScreen";
import IntroContainer from "../IntroContainer/IntroContainer";
import RoomNameScreen from "./RoomNameScreen/RoomNameScreen";
import ContactChannel from "./ContactChannel";
import ChatLobbyScreen from "../PreJoinScreens/DeviceSelectionScreen/ChatLobbyScreen";
import VideoLobbyScreen from "../PreJoinScreens/DeviceSelectionScreen/VideoLobbyScreen";
import CallMeScreen from "../CallMe/CallMeSelectionScreen";
import { CircularProgress } from "@material-ui/core";

export default function PreJoinScreens() {
  const {
    clientID,
    dcxClientName,
    locationID,
    logoURL,
    hBoxRequest,
    user,
    userAppId,
    setHRequestId,
    setUserAppId,
    setCurrentStep,
    setServerURL,
    setCoverURL,
    setCustomerDCXId,
    setSuggestedDepartment,
    setCommChannel,
    isAgent
  } = useAppState();

  const { getAudioAndVideoTracks } = useVideoContext();
  const [name, setName] = useState<string>(user?.displayName || "");
  const [step, setStep] = useState(Steps.roomNameStep);
  const [lastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [roomName, setRoomName] = useState<string>("");
  const [mediaError, setMediaError] = useState<Error>();
  const { URLRoomName } = useParams<{ URLRoomName?: string }>();
  const hostServerURL = window.location.hostname;
  const clientCover = ClientCoverImage(clientID);

  useEffect(() => {
    setCoverURL(clientCover);

    if (URLRoomName) {
      setRoomName(URLRoomName);
      setServerURL(hostServerURL);
      if (user?.displayName) {
        setStep(Steps.selectContactChannel);
        setCurrentStep(Steps.selectContactChannel);
      }
    } else {
      console.log("Are you lost!?");
      setStep(Steps.landingPage);
      setCurrentStep(Steps.landingPage);
    }

    if (step === Steps.videoLobby && !mediaError) {
      getAudioAndVideoTracks().catch(error => {
        console.log("Error acquiring local media:");
        console.dir(error);
        setMediaError(error);
      });
    }

    if (isAgent()) {
      const params = new URLSearchParams(document.location.search);
      const hrId = params.get("hrid");
      const hr = getDBHRequest(hrId);
      hr.then(data => {
        setHRequestId(data.HelpRequest.helpText);
        setSuggestedDepartment(data.HelpRequest.departmentName);
      });
    }
  }, [
    getAudioAndVideoTracks,
    setServerURL,
    step,
    mediaError,
    URLRoomName,
    setCurrentStep,
    hostServerURL,
    setCoverURL,
    clientCover,
    user?.displayName,
    isAgent,
    setHRequestId,
    setSuggestedDepartment
  ]);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let appCustomerId: any;
    let dcxCustomerId: Promise<any> | undefined = undefined;
    const dcxCustomerModel = {
      dcxCustomerId,
      clientID,
      name,
      lastName,
      email,
      phone,
      locationID,
      URLRoomName,
      appCustomerId
    };
    localStorage.setItem("DCXCLIENTCustomer", JSON.stringify(dcxCustomerModel));
    try {
      const newDCXCustomer = await RecordDCXCustomer(
        clientID,
        name,
        lastName,
        email,
        phone,
        locationID
      );
      setCustomerDCXId(newDCXCustomer);
    } catch (error) {
      console.error("Error saving Customer:", error);
    } finally {
      setStep(Steps.selectContactChannel);
      setCurrentStep(step);
    }
  };

  const createHelpRequest = async (contactChannel: string) => {
    let aiDepartment;
    if (hBoxRequest) aiDepartment = await aiDeptChoose(hBoxRequest);
    setSuggestedDepartment(aiDepartment);
    return {
      roomName: roomName,
      helpText: hBoxRequest,
      commType: contactChannel,
      departmentName: aiDepartment,
      clientId: parseInt(clientID),
      locationId: parseInt(locationID),
      authorId: parseInt(userAppId),
      updatedAt: new Date(),
      rating: "5 stars",
      status: 1 //created
    };
  };

  const handleHelpRequest = (contactChannel: string, step: Steps) => {
    setCommChannel(contactChannel);
    const helpRequest = createHelpRequest(contactChannel);
    helpRequest.then(data => {
      saveDBHRequest(data).then(savedHR => {
        setHRequestId(savedHR.HelpId);
        setCurrentStep(step);
        setStep(step);
      });
    });
  };

  const goToChat = () => {
    handleHelpRequest("CHAT", Steps.chatLobby);
  };

  const goToCall = () => {
    handleHelpRequest("CALL", Steps.goToCallStep);
  };

  const goToVideo = () => {
    handleHelpRequest("VIDEO", Steps.videoLobby);
  };

  return (
    <IntroContainer logoURL={logoURL} clientName={dcxClientName}>
      <MediaErrorSnackbar error={mediaError} />
      {step === Steps.roomNameStep && (
        <RoomNameScreen
          name={name}
          email={email}
          phone={phone}
          roomName={roomName}
          setName={setName}
          setEmail={setEmail}
          setPhone={setPhone}
          setRoomName={setRoomName}
          handleSubmit={handleSubmit}
          setStep={setStep}
        />
      )}
      {step === Steps.landingPage && <LandingScreen />}
      {step === Steps.selectContactChannel && (
        <ContactChannel
          name={name}
          email={email}
          phone={phone}
          roomName={roomName}
          setStep={setStep}
          gotoVideo={goToVideo}
          gotoChat={goToChat}
          gotoCall={goToCall}
        />
      )}
      {step === Steps.chatLobby && (
        <ChatLobbyScreen
          name={name}
          phone={phone}
          roomName={roomName}
          setStep={setStep}
        />
      )}
      {step === Steps.goToCallStep && (
        <CallMeScreen
          name={name}
          email={email}
          phone={phone}
          roomName={roomName}
          setStep={setStep}
        />
      )}
      {step === Steps.videoLobby && (
        <VideoLobbyScreen
          name={name}
          phone={phone}
          roomName={roomName}
          setStep={setStep}
        />
      )}
      {/* <div className="centrado"> <CircularProgress /> </div> */}
    </IntroContainer>
  );
}
