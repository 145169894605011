import React, { useEffect} from 'react';
import DCXLogo from '../DCXLogo';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import { useAppState } from '../../state';
import { defaultClientData, ClientLogoImage, ClientCoverImage } from '../../data/ClientData';

interface IntroContainerProps {
  children: React.ReactNode;
  logoURL: string;
  clientName: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  DCXApp: {
    backgroundColor: 'whitesmoke',
  },
  layout: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .centrado': { textAlign: 'center' },
  },
  logoContainer: {
    position: 'relative',
    top: '26px',
    zIndex: 6,
    margin: 'auto',
    textAlign: 'center',
    width: '210px',
    '& img': {
      maxHeight: '100px', borderRadius: '8px', boxShadow: '0px 0px 4px 0px rgba(40, 42, 43, 0.3)',
    },
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      width: '90%',
      zIndex: '20',
      '& svg': {
        height: '64px',
      },
    },
  },
  container: {
    position: 'relative',
    zIndex: 5,
    flex: '1',
    marginTop: '-25px',
  },
  innerContainer: {
    border: '1px solid #E4E7E9',
    display: 'flex',
    width: '888px',
    height: '379px',
    borderRadius: '8px',
    boxShadow: '0px 2px 4px 0px rgba(40, 42, 43, 0.3)',
    overflow: 'hidden',
    position: 'relative',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      height: 'auto',
      width: 'calc(100% - 40px)',
      margin: 'auto',
      maxWidth: '400px',
    },
  },
  customerBgContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'cover',
    width: '300px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '40vh',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'top',
    },
  },
  mainFooter: {
    position: 'absolute',
    fontSize: '0.82em',
    display: 'flex',
    padding: '0',
    bottom: '0',
    width: '100%',
    textAlign: 'center',
    justifyContent: 'center',
    '& .footerLogo': {
      display: 'inline-block',
      marginRight: '8px',

      '& svg': {
        height: '2em',
        width: '80px',
      },
    },
    '& .footerText': {
      paddingTop: '4px',
      position: 'relative',
      display: 'block',
      fontSize: '0.8em',
    },
  },
  content: {
    background: 'white',
    width: '100%',
    padding: '2em 3em',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      padding: '1.8em',
    },
  },
  title: {
    display: 'none',
    margin: '1em 0 0',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      fontSize: '1.1rem',
    },
  },
}));

const IntroContainer = (props: IntroContainerProps) => {
  const classes = useStyles();
  const url = new URL(window.location.href);
  const urlClientID = url.searchParams.get('ClientID') as string;
  const urlLocationID = url.searchParams.get('LocationID') as string;
  const clientLogoImage = ClientLogoImage(urlClientID);
  if (window.location.pathname !== "/landing" && !urlClientID) window.location.assign('/landing');
  const { setCoverURL, logoURL, setLogoURL, setDcxClientName, setClientID, setLocationID } = useAppState();
  const defaultData = defaultClientData();

  useEffect(() => {
    const storedClientData = JSON.parse(localStorage.getItem('DCXCLIENT') || 'null');
    setLogoURL(storedClientData?.logoURL ?? clientLogoImage ?? defaultData.logoURL);
    setCoverURL(storedClientData?.coverURL ?? defaultData.coverURL);
    setClientID(storedClientData?.clientID ?? defaultData.clientID);
    setDcxClientName(storedClientData?.dcxClientName ?? defaultData.dcxClientName);
    setLocationID(storedClientData?.locationID ?? defaultData.locationID);
  }, [setDcxClientName, setLogoURL, setLocationID, setClientID, urlClientID, urlLocationID, defaultData.logoURL, defaultData.coverURL, defaultData.clientID, defaultData.dcxClientName, defaultData.locationID, setCoverURL, clientLogoImage]);
 
  return (
    <div className={classes.DCXApp}>
      <div className={classes.layout}>
        <div className={classes.container}>

          <div className={classes.logoContainer}>
            <img src={props.logoURL || logoURL || defaultClientData().placeHolderLogo} alt={props.clientName} />
            <Typography variant="h5" className={classes.title}> {props.clientName}</Typography>
          </div>

          <div className={classes.innerContainer}>
            <div className={classes.content}>{props.children}</div>
          </div>

        </div>
        <footer className={classes.mainFooter}>
          <span className={'footerLogo'}><DCXLogo /> </span>
          <span className={'footerText'}>© Copyright 2023 Drive Business Intelligence</span>
        </footer>
      </div>
    </div>
  );
};

export default IntroContainer;