import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { Steps, useAppState} from '../../../state';
import { SendCCard } from '../../../data/ClientData';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      maxWidth: '100px',
      //background: theme.brand,
      color: '#666',
      '&:hover': {
        background: 'orange',
        color: 'white',

      },
    },
  })
);

export default function EndCallButton(props: { className?: string }) {
  const classes = useStyles();
  const { room } = useVideoContext();
  const { clientID, locationID, customerDCXId, customerPhone, currentStep } = useAppState();
  const endContact = () => {

    room!.disconnect(); 
    const storedDcxClient = JSON.parse(localStorage.getItem('DCXCLIENT') as string);
    const dcxClientURL = storedDcxClient.serviceURL;
    
    SendCCard(clientID, locationID, customerDCXId, customerPhone).then(() => {
      window.location.replace(`/${dcxClientURL}&phone=${customerPhone}`);
    }).catch((error) => { console.error('Promise rejected:', error)});

  }
  return (
    <Button variant="outlined" color="primary" onClick={endContact} className={clsx(classes.button, props.className)} data-cy-disconnect>
    { currentStep === Steps.videoLobby ? "End Call" : "End Chat"}
    </Button>
  );
}
